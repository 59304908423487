import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid2';
import AOS from "aos";
import PartnersSection from "../../components/Partners";
import Services from "../../components/Services";
import { FaIndustry, FaHospital, FaCar, FaCity, FaHome } from "react-icons/fa";
import CaseStudyModal from "../../components/CaseStudyModal";

const IoT = () => {
  const services = [
    {
      title: 'Device Connectivity',
      description: 'Seamlessly connect and manage IoT devices at scale.',
      details: 'Our platform provides robust protocols and APIs for connecting various IoT devices, ensuring real-time data exchange and interoperability.'
    },
    {
      title: 'Edge Computing',
      description: 'Process data at the edge to reduce latency and enhance efficiency.',
      details: 'With our edge computing solutions, perform analytics and processing directly on devices, enabling faster response times and reduced cloud dependency.'
    },
    {
      title: 'Data Management',
      description: 'Efficiently store, organize, and analyze IoT-generated data.',
      details: 'We offer scalable storage solutions and analytics tools to handle massive datasets generated by IoT networks, enhancing decision-making capabilities.'
    },
    {
      title: 'Security & Compliance',
      description: 'Protect your IoT ecosystem with advanced security solutions.',
      details: 'Implement encryption, authentication, and anomaly detection techniques to safeguard your devices and data from potential threats.'
    }
  ];

  const caseStudies = [
    {
      title: "Smart Traffic Management in Urban Areas",
      industry: "Smart Cities",
      category: "IoT Traffic Control",
      icon: <FaCity size={24} />,
      image: "https://via.placeholder.com/600x300",
      shortDescription: "Reduced city traffic congestion by 30% using AI-powered IoT sensors.",
      fullDescription: `
        A metropolitan city implemented AI-driven IoT traffic management, reducing congestion.
  
        🔹 **Results:**
        - 30% decrease in peak-hour traffic congestion.
        - 25% reduction in fuel consumption for commuters.
        - Improved emergency vehicle response time by 40%.
  
        🔹 **Tech Used:** IoT Sensors, AI Traffic Prediction, 5G Networks.
      `,
    },
    {
      title: "IoT-Enabled Remote Patient Monitoring",
      industry: "Healthcare",
      category: "IoT in Medicine",
      icon: <FaHospital size={24} />,
      image: "https://via.placeholder.com/600x300",
      shortDescription: "Reduced hospital readmissions by 40% with real-time IoT patient monitoring.",
      fullDescription: `
        A healthcare provider adopted IoT for remote patient monitoring, reducing hospital visits.
  
        🔹 **Results:**
        - 40% decrease in hospital readmissions.
        - 50% faster diagnosis time.
        - Improved patient recovery rates by 35%.
  
        🔹 **Tech Used:** Wearable IoT Devices, Cloud-Based Health Analytics, AI Diagnostics.
      `,
    },
    {
      title: "Predictive Maintenance in Manufacturing",
      industry: "Manufacturing",
      category: "IoT for Industrial Automation",
      icon: <FaIndustry size={24} />,
      image: "https://via.placeholder.com/600x300",
      shortDescription: "Cut production downtime by 60% using IoT-based predictive maintenance.",
      fullDescription: `
        A manufacturing company used IoT sensors to predict machine failures, reducing downtime.
  
        🔹 **Results:**
        - 60% reduction in production downtime.
        - 30% decrease in maintenance costs.
        - 99.5% operational efficiency achieved.
  
        🔹 **Tech Used:** IoT Sensors, AI Predictive Analytics, Edge Computing.
      `,
    },
    {
      title: "IoT Fleet Management for Logistics",
      industry: "Transportation",
      category: "IoT in Supply Chain",
      icon: <FaCar size={24} />,
      image: "https://via.placeholder.com/600x300",
      shortDescription: "Optimized delivery routes and saved 25% on fuel costs.",
      fullDescription: `
        A logistics company integrated IoT fleet tracking for route optimization and cost reduction.
  
        🔹 **Results:**
        - 25% savings in fuel costs.
        - 20% increase in on-time deliveries.
        - 99.8% accuracy in vehicle tracking.
  
        🔹 **Tech Used:** GPS IoT Trackers, AI Route Optimization, Cloud Fleet Management.
      `,
    },
    {
      title: "IoT-Based Smart Home Energy Management",
      industry: "Smart Homes",
      category: "IoT for Energy Efficiency",
      icon: <FaHome size={24} />,
      image: "https://via.placeholder.com/600x300",
      shortDescription: "Reduced household energy bills by 35% with smart IoT home automation.",
      fullDescription: `
        A smart home provider developed an IoT-based energy management system.
  
        🔹 **Results:**
        - 35% reduction in energy bills.
        - 25% lower carbon footprint.
        - 100% remote home control via mobile app.
  
        🔹 **Tech Used:** IoT Smart Meters, AI Energy Optimization, Home Automation Systems.
      `,
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const [selectedCase, setSelectedCase] = useState(null);

  return (
    <div className="bg-dark text-white">
      {/* Header Section */}
      <section className="text-center py-5" >
        <Grid container spacing={2} className="container containerStyle" data-aos="fade-up">
          <Grid size={5} className="columnStyle">
          <img
            src="/images/IoT_img.avif"
            alt="IoT"
            className="img-fluid rounded shadow-sm mt-4"
          />
          </Grid>
          <Grid size={7} className="columnStyle">
            <p></p>
            <h1 className="display-4 fw-bold mb-3">Internet of Things (IoT): Smart Connectivity</h1>
            <p className="lead">
              Drive efficiency and automation with intelligent IoT solutions.
            </p>
         </Grid>
        </Grid>
      </section>

      {/* Why Choose Us */}
      <section className="py-5">
        <div className="container">
          <h2 className="h2 fw-bold border-bottom border-secondary pb-2 mb-4">Why Choose IoT Solutions?</h2>
          <div className="row g-4">
          <div className="col-md-4">
            <h5 className="fw-bold short_line">75% Enhanced Connectivity</h5>
            <p>Seamlessly connect devices to improve efficiency and data exchange.</p>
          </div>
          <div className="col-md-4">
            <h5 className="fw-bold short_line">65% Operational Efficiency Improvement</h5>
            <p>Leverage real-time data for predictive maintenance and optimized workflows.</p>
          </div>
          <div className="col-md-4">
            <h5 className="fw-bold short_line">60% Cost Reduction</h5>
            <p>Reduce energy consumption and minimize downtime with smart monitoring.</p>
          </div>
          </div>
        </div>
      </section>

      {/* Our IoT Services */}
      <Services servicesData = {services} />

      <CaseStudyModal caseStudies={caseStudies} />

      <PartnersSection />

      {/* Call to Action */}
      {/* <section className="text-center py-5 text-white">
        <div className="container">
          <h2 className="fw-bold mb-3">Transform Your World with IoT</h2>
          <p className="lead mb-4  mb-4">
            From smart devices to industrial systems, our IoT solutions are designed to revolutionize your operations and enhance your lifestyle. Let’s create the future together.
          </p>
          <a href="/contact" className="btn btn-primary btn-lg">
            Contact Us
          </a>
        </div>
      </section> */}
    </div>
  );
};

export default IoT;