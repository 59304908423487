import React from "react";

export default function PartnersSection() {
  const partners = [
    { name: "AWS", logo: "/images/AWS-2.png" },
    { name: "Google", logo: "/images/google_logo.png" },
  ];

  return (
    <section className="py-5 bg-dark text-center">
      <div className="container">
        <h2 className="mb-5 fw-bold">Our Partners</h2>
        <div className="row">
          {partners.map((partner, index) => (
            <div key={index} className="col-3 col-md-3 mb-4 d-flex justify-content-center">
              <img
                src={partner.logo}
                alt={partner.name}
                className="img-fluid rounded mb-2"
                style={{ width: "200px", height: "70px" }}
              />
              {/* <p className="text-muted">{partner.name}</p> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}